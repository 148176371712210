body {
  height: 100vh;
  overflow: hidden;
  // background: linear-gradient(45deg,#1b14d2, #9203ff)
}

.container {
  z-index: 2;
}

.heading {
  letter-spacing: 10px;
  font-size: 40px;
}

.form-control {
  border-color: #fff;
  background: transparent;
  padding: 10px 15px;

  &::placeholder {
    color: #fff !important;
    font-size: 14px;
  }
}

.mail-box {
  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(45deg, #2164f9, #11d9ef);    
    width: 1200px;
    height: 1200px;
    border-radius: 15%;
    opacity: .9;
    top: -750px;
    right: 3%;
    transform: rotate(45deg);
    box-shadow: 0 0 32px rgba(33, 100, 249, 0.24);
    z-index: 1;
    @media only screen and (max-width: 600px) {
      right: -40%;
    }
  }
  &::after {
    content: '';
    position: absolute;
    background: linear-gradient(#2164f9, #11d9ef);
    width: 1200px;
    height: 1200px;
    border-radius: 15%;
    bottom: -750px;
    right: 12%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1;
  }
}